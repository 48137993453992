import { ListBullets, SquaresFour } from 'phosphor-react';
import React from 'react';
import Placeholder from '../assets/images/imagePlaceholder.png';
/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  LOGOUT: '/logout',
  LOGIN: '/login',
  FORGET_PASSWORD: '/forgot-password',
  RESET: '/reset-password',
  VERIFY: '/verify',
  AUTHENTICATION: '/authentication',
  PROFILE: '/profile',
  IMAGES: '/images',
  VIDEOS: '/videos',
  DOCUMENT: '/document',
  AUDIO: '/audio',
  TEXT: '/text',
  ICONS: '/icons',
  AUTHOR: '/speakers',
  TOPICS: '/topics',
  COLLECTIONS: '/collections',
  TAGS: '/tags',
  VIDEOS_MODULE: '/videos-module',
  PAGES_MODULE: '/pages-module',
  COMPONENT: '/component',
  MENUS: '/menus',
  CONVERSATIONS: '/conversations',
  UI_LABELS: '/ui-labels',
  USERS: '/users',
  DONATIONS: '/donations',
  LEAD_GENS: '/lead-gens',
  CONTACT_INQUIRY: '/contact-inquiry',
  EXPORT_JOBS: '/export-jobs',
  DASHBOARD: '/dashboard-analytics',
  IMPORT_JOBS: '/import-jobs'
};

/*  Modules */
export const MODULES = {
  PROFILE: 'PROFILE',
  ASSETS: 'ASSETS',
  LABELS: 'LABELS',
  IMAGES: 'IMAGES',
  VIDEOS: 'VIDEOS',
  DOCUMENT: 'DOCUMENT',
  AUDIO: 'AUDIO',
  TEXT: 'TEXT',
  ICONS: 'ICONS',
  AUTHOR: 'SPEAKERS',
  TOPICS: 'TOPICS',
  PAGES: 'PAGES',
  COLLECTIONS: 'COLLECTIONS',
  TAGS: 'TAGS',
  COMPONENT: 'COMPONENT',
  MENUS: 'MENUS',
  CONVERSATIONS: 'CONVERSATIONS',
  UI_LABELS: 'UI LABELS',
  USERS: 'USERS',
  DONATIONS: 'DONATIONS',
  LEAD_GENS: 'LEAD GENS',
  CONTACT_INQUIRY: 'CONTACT INQUIRY',
  EXPORT_JOBS: 'EXPORT JOBS',
  IMPORT_JOBS: 'IMPORT JOBS',
  DASHBOARD: 'DASHBOARD'
};

export const ASSET_CATEGORY = {
  VIDEO: 'VIDEO',
  TEXT: 'TEXT',
  AUDIO: 'AUDIO',
  IMAGE: 'IMAGE',
  DOCUMENT: 'DOCUMENT',
  ICON: 'ICON'
};

/* Authentication */
export const TOKEN = 'TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER = 'USER';

export const ROLES = {
  SUPER_ADMIN: 'Super admin',
  ADMIN: 'Admin'
};

export const ROLE_KEYS = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN'
};

/* Date and time */
export const defaultDateFormat = 'MM/DD/YYYY';

export const VIDEOS = [
  {
    id: 1,
    title: 'Elephant Dream',
    thumb: Placeholder,
    tracks: [
      {
        kind: 'subtitles',
        label: 'English',
        src:
          'https://video3.cappital.co/lazy-static/video-captions/76487ab0-7499-419b-80af-03b662cdbc93-en.vtt',
        srcLang: 'en',
        default: false
      },
      {
        kind: 'subtitles',
        label: 'French',
        src:
          'https://video3.cappital.co/lazy-static/video-captions/91677363-5e15-44f4-bd50-76b6d9fc056c-de.vtt',
        srcLang: 'de',
        default: false
      },
      {
        kind: 'chapters',
        src:
          'https://s3.amazonaws.com/demo.jwplayer.com/text-tracks/assets/chapters.vtt',
        default: true
      }
    ],
    url:
      'https://video3cdn.cappital.co/streaming-playlists/hls/19189e03-b90f-4d0e-991d-4c8aee34a5e9/5f44e513-d398-4f93-8e8a-074d788fc0fc-master.m3u8'
  },
  {
    id: 2,
    title: 'Big Buck Bunny',
    thumb: Placeholder,
    tracks: [
      {
        kind: 'subtitles',
        label: 'English',
        src:
          'https://video3.cappital.co/lazy-static/video-captions/76487ab0-7499-419b-80af-03b662cdbc93-en.vtt',
        srcLang: 'en',
        default: false
      },
      {
        kind: 'subtitles',
        label: 'French',
        src:
          'https://video3.cappital.co/lazy-static/video-captions/91677363-5e15-44f4-bd50-76b6d9fc056c-de.vtt',
        srcLang: 'de',
        default: false
      },
      {
        kind: 'chapters',
        src:
          'https://s3.amazonaws.com/demo.jwplayer.com/text-tracks/assets/chapters.vtt',
        default: true
      }
    ],
    url:
      'https://peertubetv.s3.eu-central-1.wasabisys.com/streaming-playlists/hls/f37656eb-be47-4289-bda3-5851ca40a501/59755baf-d2b4-461d-9333-12faa7908924-master.m3u8'
  },
  {
    id: 3,
    title: 'For Bigger Escape',
    thumb: Placeholder,
    tracks: [
      {
        kind: 'subtitles',
        label: 'English',
        src:
          'https://video3.cappital.co/lazy-static/video-captions/76487ab0-7499-419b-80af-03b662cdbc93-en.vtt',
        srcLang: 'en',
        default: false
      },
      {
        kind: 'subtitles',
        label: 'French',
        src:
          'https://video3.cappital.co/lazy-static/video-captions/91677363-5e15-44f4-bd50-76b6d9fc056c-de.vtt',
        srcLang: 'de',
        default: false
      },
      {
        kind: 'chapters',
        src:
          'https://s3.amazonaws.com/demo.jwplayer.com/text-tracks/assets/chapters.vtt',
        default: true
      }
    ],
    url:
      'https://video3cdn.cappital.co/streaming-playlists/hls/65fb42ad-7dd4-410f-8a28-63fc8f6111e7/2067a88e-baa9-4dfe-800e-3bb3ef8ae688-master.m3u8'
  },
  {
    id: 4,
    title: 'For Bigger Joyrides',
    thumb: Placeholder,
    tracks: [
      {
        kind: 'subtitles',
        label: 'English',
        src:
          'https://video3.cappital.co/lazy-static/video-captions/76487ab0-7499-419b-80af-03b662cdbc93-en.vtt',
        srcLang: 'en',
        default: false
      },
      {
        kind: 'subtitles',
        label: 'French',
        src:
          'https://video3.cappital.co/lazy-static/video-captions/91677363-5e15-44f4-bd50-76b6d9fc056c-de.vtt',
        srcLang: 'de',
        default: false
      },
      {
        kind: 'chapters',
        src:
          'https://s3.amazonaws.com/demo.jwplayer.com/text-tracks/assets/chapters.vtt',
        default: true
      }
    ],
    url:
      'https://video3cdn.cappital.co/streaming-playlists/hls/5f5f20b3-5f39-425e-bca5-d8af2a8c14d4/13338140-c8e1-4cf0-8641-7a9f23b9881d-master.m3u8'
  },
  {
    id: 5,
    title: 'Elephant Dream',
    thumb: Placeholder,
    tracks: [
      {
        kind: 'subtitles',
        label: 'English',
        src:
          'https://video3.cappital.co/lazy-static/video-captions/76487ab0-7499-419b-80af-03b662cdbc93-en.vtt',
        srcLang: 'en',
        default: false
      },
      {
        kind: 'subtitles',
        label: 'French',
        src:
          'https://video3.cappital.co/lazy-static/video-captions/91677363-5e15-44f4-bd50-76b6d9fc056c-de.vtt',
        srcLang: 'de',
        default: false
      },
      {
        kind: 'chapters',
        src:
          'https://s3.amazonaws.com/demo.jwplayer.com/text-tracks/assets/chapters.vtt',
        default: true
      }
    ],
    url:
      'https://video3cdn.cappital.co/streaming-playlists/hls/5f5f20b3-5f39-425e-bca5-d8af2a8c14d4/13338140-c8e1-4cf0-8641-7a9f23b9881d-master.m3u8'
  },
  {
    id: 6,
    title: 'Big Buck Bunny',
    thumb: Placeholder,
    tracks: [
      {
        kind: 'subtitles',
        label: 'English',
        src:
          'https://video3.cappital.co/lazy-static/video-captions/76487ab0-7499-419b-80af-03b662cdbc93-en.vtt',
        srcLang: 'en',
        default: false
      },
      {
        kind: 'subtitles',
        label: 'French',
        src:
          'https://video3.cappital.co/lazy-static/video-captions/91677363-5e15-44f4-bd50-76b6d9fc056c-de.vtt',
        srcLang: 'de',
        default: false
      },
      {
        kind: 'chapters',
        src:
          'https://s3.amazonaws.com/demo.jwplayer.com/text-tracks/assets/chapters.vtt',
        default: true
      }
    ],
    url:
      'https://peertubetv.s3.eu-central-1.wasabisys.com/streaming-playlists/hls/f37656eb-be47-4289-bda3-5851ca40a501/59755baf-d2b4-461d-9333-12faa7908924-master.m3u8'
  },
  {
    id: 7,
    title: 'For Bigger Escape',
    thumb: Placeholder,
    tracks: [
      {
        kind: 'subtitles',
        label: 'English',
        src:
          'https://video3.cappital.co/lazy-static/video-captions/76487ab0-7499-419b-80af-03b662cdbc93-en.vtt',
        srcLang: 'en',
        default: false
      },
      {
        kind: 'subtitles',
        label: 'French',
        src:
          'https://video3.cappital.co/lazy-static/video-captions/91677363-5e15-44f4-bd50-76b6d9fc056c-de.vtt',
        srcLang: 'de',
        default: false
      },
      {
        kind: 'chapters',
        src:
          'https://s3.amazonaws.com/demo.jwplayer.com/text-tracks/assets/chapters.vtt',
        default: true
      }
    ],
    url:
      'https://video3cdn.cappital.co/streaming-playlists/hls/65fb42ad-7dd4-410f-8a28-63fc8f6111e7/2067a88e-baa9-4dfe-800e-3bb3ef8ae688-master.m3u8'
  },
  {
    id: 8,
    title: 'For Bigger Joyrides',
    thumb: Placeholder,
    tracks: [
      {
        kind: 'subtitles',
        label: 'English',
        src:
          'https://video3.cappital.co/lazy-static/video-captions/76487ab0-7499-419b-80af-03b662cdbc93-en.vtt',
        srcLang: 'en',
        default: false
      },
      {
        kind: 'subtitles',
        label: 'French',
        src:
          'https://video3.cappital.co/lazy-static/video-captions/91677363-5e15-44f4-bd50-76b6d9fc056c-de.vtt',
        srcLang: 'de',
        default: false
      },
      {
        kind: 'chapters',
        src:
          'https://s3.amazonaws.com/demo.jwplayer.com/text-tracks/assets/chapters.vtt',
        default: true
      }
    ],
    url:
      'https://video3cdn.cappital.co/streaming-playlists/hls/5f5f20b3-5f39-425e-bca5-d8af2a8c14d4/13338140-c8e1-4cf0-8641-7a9f23b9881d-master.m3u8'
  }
];
// export const VIDEO_URL =
//   'https://peertubetv.s3.eu-central-1.wasabisys.com/streaming-playlists/hls/f37656eb-be47-4289-bda3-5851ca40a501/59755baf-d2b4-461d-9333-12faa7908924-master.m3u8';

export const SHARE_URL = 'http://localhost:3000/video-player';

export const TRACK_KIND = {
  SUBTITLES: 'subtitles',
  CAPTIONS: 'captions',
  CHAPTERS: 'chapters'
};

export const TRACK_MODE = {
  SHOWING: 'showing',
  DISABLED: 'disabled'
};

export const PLAYBACK_RATES = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

export const defaultSubtitle = 'English';

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL: /^(([^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+(\.[^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  COUNTRY_CODE_PHONE: /^[0-9]{7,12}$/
};

export const LIMIT = 15;
export const SCROLL_PERCENT = 0.85;

export const SORT_FIELD = [
  { name: 'Created At', value: 'createdAt' },
  { name: 'Title', value: 'title' }
];
export const AUTHOR_SORT_FIELD = [
  { name: 'Created At', value: 'createdAt' },
  { name: 'Firstname', value: 'firstName' },
  { name: 'Lastname', value: 'lastName' }
];
export const PAGES_SORT_FIELD = [
  { name: 'Created At', value: 'createdAt' },
  { name: 'Title', value: 'title' },
  { name: 'Status', value: 'status' }
];
export const USERS_SORT_FIELD = [
  { name: 'Created At', value: 'createdAt' },
  { name: 'First Name', value: 'firstName' },
  { name: 'Last Name', value: 'lastName' },
  { name: 'Email', value: 'email' }
];

export const TAGS_SORT_FIELD = [
  { name: 'Created At', value: 'createdAt' },
  { name: 'Name', value: 'name' }
];
export const TOPICS_SORT_FIELD = [
  ...TAGS_SORT_FIELD,
  { name: 'Key', value: 'key' }
];
export const ORDER = [
  { name: 'Ascending', value: 'ASC' },
  { name: 'Descending', value: 'DESC' }
];
export const CREDIT_TYPE = [
  { name: 'Cast', value: 'CAST' },
  { name: 'Crew', value: 'CREW' }
];

export const GENDER = [
  { name: 'Female', value: 'FEMALE' },
  { name: 'Male', value: 'MALE' },
  { name: 'Other', value: 'OTHER' }
];

export const LIVE_VIDEO_OPTIONS = [
  { name: 'All', value: '' },
  { name: 'Live', value: true },
  { name: 'Non Live', value: false }
];

export const LIVE_VIDEO_STATUS = [
  { name: 'Pending', value: 'PENDING' },
  { name: 'Live', value: 'LIVE' },
  { name: 'Paused', value: 'PAUSED' },
  { name: 'Completed', value: 'COMPLETED' }
];

export const USER_ROLES = [
  {
    name: 'User',
    value: 'USER'
  },
  {
    name: 'Admin',
    value: 'ADMIN'
  },
  {
    name: 'Super Admin',
    value: 'SUPER_ADMIN'
  }
];

export const ERROR_PAGE_TITLE = 'Oops! An error occurred!';
export const ERROR_PAGE_SUBTITLE =
  'Something is broken. Please let us know what you were doing when this error occurred. We will fix it as soon as possible. Sorry for any inconvenience caused.';

export const GUTTER_VARIATIONS = { xs: 16, sm: 16, md: 24, lg: 32 };

export const BREAKPOINTS = {
  mobile: 550,
  tablet: 767,
  desktop: 1000,
  hd: 1200
};

export const COLOUR_CODES = [
  {
    color: 'bg-red-2'
  },
  {
    color: 'bg-yellow-2'
  },
  {
    color: 'bg-blue-2'
  },
  {
    color: 'bg-moss-2'
  }
];

export const COLLECTIONS_COLORS = ['bg-n-800', 'bg-blue-2', 'bg-moss-2'];

export const STATUS_TYPES = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
  UNPUBLISHED: 'UNPUBLISHED'
};

export const STATUS_OPTIONS = [
  { name: 'Draft', value: STATUS_TYPES.DRAFT },
  { name: 'Published', value: STATUS_TYPES.PUBLISHED }
];

export const UNPUBLISHED_STATUS = {
  name: 'Unlisted',
  value: STATUS_TYPES.UNPUBLISHED
};

export const LIST_TYPES = {
  GRID: 'grid',
  TABLE: 'table'
};

export const LIST_OPTIONS = [
  {
    name: 'Grid view',
    icon: <SquaresFour size={32} />,
    value: LIST_TYPES.GRID
  },
  {
    name: 'List view',
    icon: <ListBullets size={32} />,
    value: LIST_TYPES.TABLE
  }
];

export const PAGE_MODULE_TYPES = {
  VIDEO_CONTINUE_WATCHING_CAROUSEL: 'VIDEO_CONTINUE_WATCHING_CAROUSEL',
  VIDEO_WATCH_LATER_CAROUSEL: 'VIDEO_WATCH_LATER_CAROUSEL',
  VIDEO_CAROUSEL: 'VIDEO_CAROUSEL',
  VIDEO_LIST: 'VIDEO_LIST',
  VIDEO_GRID: 'VIDEO_GRID',
  FEATURED_VIDEO: 'FEATURED_VIDEO',
  FEATURED_VIDEO_SLIDER: 'FEATURED_VIDEO_SLIDER',
  COLLECTION_CAROUSEL: 'COLLECTION_CAROUSEL',
  COLLECTION_LIST: 'COLLECTION_LIST',
  COLLECTION_GRID: 'COLLECTION_GRID',
  FEATURED_COLLECTION: 'FEATURED_COLLECTION'
};

export const AUTO_GENERATED_SORT_BY_TYPES = {
  RECENT: 'RECENT',
  RANDOM: 'RANDOM',
  VIEWS: 'VIEWS'
};

export const MODULE_TYPES = {
  SETTINGS: 'SETTINGS',
  ADD: 'ADD',
  VIDEO_CONTINUE_WATCHING_CAROUSEL: 'VIDEO_CONTINUE_WATCHING_CAROUSEL',
  VIDEO_WATCH_LATER_CAROUSEL: 'VIDEO_WATCH_LATER_CAROUSEL',
  VIDEO_CAROUSEL: 'VIDEO_CAROUSEL',
  VIDEO_LIST: 'VIDEO_LIST',
  VIDEO_GRID: 'VIDEO_GRID',
  VIDEO_PLAYER: 'VIDEO_PLAYER',
  LIVE_VIDEO_PLAYER: 'LIVE_VIDEO_PLAYER',
  FEATURED_VIDEO: 'FEATURED_VIDEO',
  FEATURED_VIDEO_SLIDER: 'FEATURED_VIDEO_SLIDER',
  COLLECTION_CAROUSEL: 'COLLECTION_CAROUSEL',
  COLLECTION_LIST: 'COLLECTION_LIST',
  COLLECTION_GRID: 'COLLECTION_GRID',
  FEATURED_COLLECTION: 'FEATURED_COLLECTION',
  FEATURED_COLLECTION_SLIDER: 'FEATURED_COLLECTION_SLIDER',
  AUTHOR_CAROUSEL: 'AUTHOR_CAROUSEL',
  AUTHOR_GRID: 'AUTHOR_GRID',
  AUTHOR_LIST: 'AUTHOR_LIST',
  FEATURED_AUTHOR: 'FEATURED_AUTHOR',
  TOPIC_CAROUSEL: 'TOPIC_CAROUSEL',
  TOPIC_GRID: 'TOPIC_GRID',
  TOPIC_LIST: 'TOPIC_LIST',
  FEATURED_TOPIC: 'FEATURED_TOPIC',
  TAG_CAROUSEL: 'TAG_CAROUSEL',
  TAG_CLOUD: 'TAG_CLOUD',
  FEATURED_TAG: 'FEATURED_TAG',
  CTA_BLOCK: 'CTA_BLOCK',
  CTA_BANNER: 'CTA_BANNER',
  DONATION: 'DONATION',
  CTA_EVENT_BLOCK: 'CTA_EVENT_BLOCK',
  CTA_BANNER_WITH_TEXT: 'CTA_BANNER_WITH_TEXT',
  COPY_HEAVY: 'COPY_HEAVY',
  COPY_HEAVY_HERO: 'COPY_HEAVY_HERO',
  QUOTE: 'QUOTE',
  HERO: 'HERO',
  INFORMATION: 'INFORMATION',
  LEAD_GEN: 'LEAD_GEN',
  FAQ: 'FAQ',
  CONTACT_US: 'CONTACT_US',
  SPACER: 'SPACER',
  HTML_TEXT_BLOCK: 'HTML_TEXT_BLOCK'
};

export const DAYS = {
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday'
};

export const TABLE_LOCATION_TYPES = {
  PHYSICAL: 'Physical',
  REMOTE: 'Online'
};

export const THEMES = {
  NEUTRAL_300: 'NEUTRAL_300',
  NEUTRAL_400: 'NEUTRAL_400',
  NEUTRAL_900: 'NEUTRAL_900',
  NEUTRAL_1000: 'NEUTRAL_1000',
  BLUE: 'BLUE',
  BLUE_SHADE: 'BLUE_SHADE',
  BLUE_TINT: 'BLUE_TINT',
  MOSS: 'MOSS',
  MOSS_TINT: 'MOSS_TINT',
  YELLOW: 'YELLOW'
};

export const THEME_PICKER_COLORS = {
  [THEMES.NEUTRAL_300]: {
    backgroundColor: 'bg-n-300',
    textLight: false
  },
  [THEMES.NEUTRAL_400]: {
    backgroundColor: 'bg-n-400',
    textLight: false
  },
  [THEMES.NEUTRAL_900]: {
    backgroundColor: 'bg-n-900',
    textLight: true
  },
  [THEMES.NEUTRAL_1000]: {
    backgroundColor: 'bg-n-1000',
    textLight: true
  },
  [THEMES.BLUE]: {
    backgroundColor: 'bg-blue-2',
    textLight: false
  },
  [THEMES.BLUE_SHADE]: {
    backgroundColor: 'bg-blue-3',
    textLight: true
  },
  [THEMES.BLUE_TINT]: {
    backgroundColor: 'bg-blue',
    textLight: false
  },
  [THEMES.MOSS]: {
    backgroundColor: 'bg-moss-2',
    textLight: false
  },
  [THEMES.MOSS_TINT]: {
    backgroundColor: 'bg-moss',
    textLight: false
  },
  [THEMES.YELLOW]: {
    backgroundColor: 'bg-yellow-2',
    textLight: false
  }
};

export const ALIGNMENTS = {
  LEFT: 'LEFT',
  CENTER: 'CENTER',
  RIGHT: 'RIGHT'
};

export const PAYMENT_STATUS = {
  SUCCESS: 'SUCCESS',
  PENDING: 'PENDING',
  FAILED: 'FAILED'
};

export const SUBSCRIPTION_STATUS = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  CANCELLED: 'CANCELLED',
  PAYMENT_DUE: 'PAYMENT_DUE'
};

export const DONATION_TYPES = {
  ONE_TIME: 'ONE_TIME',
  MONTHLY: 'MONTHLY',
  ANNUALLY: 'ANNUALLY'
};

export const STATUS_COLORS = {
  PENDING: '#0958D9',
  PROCESSING: '#0958D9',
  PAYMENT_DUE: '#FAAD14',
  EXPORTING: '#FAAD14',
  IMPORTING: '#FAAD14',
  ERRORED: '#FF4D4F',
  FAILED: '#FF4D4F',
  PARTIALLY_FAILED: '#FF4D4F',
  CANCELLED: '#FF4D4F',
  READY: '#52C41A',
  SUCCESS: '#52C41A',
  ACTIVE: '#52C41A',
  COMPLETED: '#52C41A'
};

export const LIVE_STATUS_COLORS = {
  PENDING: 'grey',
  LIVE: 'green',
  PAUSED: 'orange',
  COMPLETED: 'red'
};

export const DATA_COLLECTION_TYPE_KEYS = {
  NEWS_LETTER: 'NEWS_LETTER',
  CONTACT_INQUIRY: 'CONTACT_INQUIRY'
};

export const STATIC_PAGES = [
  { label: 'Topics', value: 'topics' },
  { label: 'Tags', value: 'tags' },
  { label: 'Speakers', value: 'speakers' },
  { label: 'Collections', value: 'collections' },
  { label: 'Videos', value: 'videos' },
  { label: 'Search', value: 'search' },
  { label: 'Manage Donation', value: 'manage-donation' },
  { label: 'Conversations', value: 'conversations' },
  { label: 'My Conversations', value: 'conversations/my-conversations' },
  { label: 'My Playlists', value: 'playlists' },
  { label: 'Account Settings', value: 'settings' },
  { label: 'Login', value: 'login' },
  { label: 'Signup', value: 'signup' },
  { label: 'Reset Password', value: 'reset-password' },
  { label: 'Change Password', value: 'change-password' },
  { label: 'Terms & Conditions', value: 'terms-and-conditions' },
  { label: 'Privacy Policy', value: 'privacy-policy' }
];

export const RATIOS = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE'
};

export const SPACER_OPTIONS = [
  { label: '0px', value: 's-0' },
  { label: '5px', value: 's-5' },
  { label: '10px', value: 's-10' },
  { label: '15px', value: 's-15' },
  { label: '20px', value: 's-20' },
  { label: '25px', value: 's-25' },
  { label: '30px', value: 's-30' },
  { label: '35px', value: 's-35' },
  { label: '40px', value: 's-40' },
  { label: '45px', value: 's-45' },
  { label: '50px', value: 's-50' }
];

export const WEB_ROUTES = {
  AUTHOR: '/speakers',
  COLLECTION: '/collections',
  TAG: '/tags',
  TOPIC: '/topics',
  CUSTOM: '',
  VIDEO: '/videos'
};

export const TEMPLATE_OPTION = {
  label: '*',
  value: '*'
};

export const DATE_FORMAT = 'M/D/YY';
export const FULL_DATE_TIME_FORMAT = 'LT L';

export const EXPORT_JOB_TYPES = {
  USER: 'USER'
};

export const EXPORT_JOB_OPTIONS = [
  { label: 'User', value: EXPORT_JOB_TYPES.USER }
];

export const TEXT_ALIGNMENTS = {
  LEFT: 'LEFT',
  CENTER: 'CENTER',
  RIGHT: 'RIGHT',
  JUSTIFY: 'JUSTIFY'
};

export const TEXT_ALIGNMENTS_CLASS = {
  [TEXT_ALIGNMENTS.LEFT]: 'text-left',
  [TEXT_ALIGNMENTS.CENTER]: 'text-center',
  [TEXT_ALIGNMENTS.RIGHT]: 'text-right',
  [TEXT_ALIGNMENTS.JUSTIFY]: 'text-justify'
};

export const PAGE_TYPES = {
  AUTHOR: 'AUTHOR',
  COLLECTION: 'COLLECTION',
  TAG: 'TAG',
  TOPIC: 'TOPIC',
  VIDEO: 'VIDEO',
  CUSTOM: 'CUSTOM',
  STATIC: 'STATIC'
};

export const PAGE_TYPES_OPTIONS = [
  { label: 'Speaker', value: PAGE_TYPES.AUTHOR },
  { label: 'Collection', value: PAGE_TYPES.COLLECTION },
  { label: 'Tag', value: PAGE_TYPES.TAG },
  { label: 'Topic', value: PAGE_TYPES.TOPIC },
  { label: 'Video', value: PAGE_TYPES.VIDEO },
  { label: 'Custom', value: PAGE_TYPES.CUSTOM },
  { label: 'Static', value: PAGE_TYPES.STATIC }
];

export const CLOUD_FRONT_CACHE_KEYS = {
  MENU: 'MENU',
  UI_LABELS: 'UI_LABELS',
  OG_IMAGES: 'OG_IMAGES'
};

export const EDITOR_MODULES = {
  toolbar: [
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'link'],
    [
      { align: [] },
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' }
    ]
  ]
};

export const VIDEO_RESOURCES_TYPES = {
  QUESTIONS: 'questions',
  QUOTES: 'quotes',
  LINKS: 'links',
  SCRIPTURE: 'scripture'
};

export const PAGE_TYPE_LABELS = {
  AUTHOR: 'SPEAKER',
  COLLECTION: 'COLLECTION',
  TAG: 'TAG',
  TOPIC: 'TOPIC',
  VIDEO: 'VIDEO',
  CUSTOM: 'CUSTOM'
};

export const UI_LABEL_TYPES = {
  STRING: 'STRING',
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  HTML: 'HTML'
};
export const VIDEO_STATUS = {
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
  ERRORED: 'ERRORED',
  READY: 'READY'
};

export const IMPORT_JOBS_STATIC_FILES_URL = {
  [EXPORT_JOB_TYPES.USER]: 'users.csv'
};

export const MENU_TYPES = { INTERNAL: 'INTERNAL', EXTERNAL: 'EXTERNAL' };

export const MENU_TYPES_OPTIONS = [
  { label: 'Internal', value: MENU_TYPES.INTERNAL },
  { label: 'External', value: MENU_TYPES.EXTERNAL }
];

export const APP_TYPES = {
  WEB: 'WEB',
  MOBILE: 'MOBILE',
  OTT: 'OTT'
};

export const APP_TYPE_OPTIONS = [
  { label: 'Web', value: APP_TYPES.WEB },
  { label: 'Mobile', value: APP_TYPES.MOBILE },
  { label: 'OTT', value: APP_TYPES.OTT }
];

export const ASSET_CONTENT_TYPE = {
  PNG: 'PNG',
  SVG: 'SVG'
};
